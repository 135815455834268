<template>
<div class="company-bg">
    <div class="container">
      <div class="banner">
        <div class="banner-title"><span>企业风采</span></div>
        <img src="../../assets/company/banner-country.png" class="banner-country" />
      </div>
      <div class="content-wrap">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/home">首页</a></li>
            <li class="breadcrumb-item">
              <a @click="$router.back(-1)"
                 href="javascript:void()">企业风采</a>
            </li>
            <li class="breadcrumb-item active"
                aria-current="page">
              详情
            </li>
          </ol>
        </nav>
        <div class="article-title">
          {{info.title}}
        </div>
        <div class="article-info">
          <div class="article-info-left">
            <div class="article-time">{{ info.created_at }}</div>
            <!-- <div class="article-time">来源：河北省应急管理宣传教育中心</div> -->
          </div>
          <div class="article-des">
            <span><i class="iconfont icon-flowrate"></i> {{info.flowrate}}</span>
            <div @click="toFavourite">
              <span><i class="iconfont icon-favourite_normal"
                   v-if="info.is_favourite==0"></i></span>
              <span><i class="iconfont icon-favourite_selected"
                   v-if="info.is_favourite==1"></i></span>
            </div>

          </div>
        </div>
        <div class="rich-text"
             v-html="decodeContent(info.content)">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { paperDetail, toPaperFavourite } from '@/network/API.js';
export default {
  data() {
    return {
      info: {},
    }
  },
  async created() {
    if (this.$route.params.id) {
      this.info = await paperDetail(this.$route.params.id)
    }
  },
  methods: {
    async toFavourite() {
      let res = await toPaperFavourite({
        id: this.info.paper_id,
        code: "fengcai",
      })
      this.info.is_favourite = res.is_favourite
      if (this.info.is_favourite == 1) {
        this.$toast("收藏成功");
      } else {
        this.$toast("取消收藏");
      }
    }
  }
}
</script>